import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo-black.719c6643_300x.png"

const Header = ({ siteTitle }) => (
  <header
    class="site-header header-section"
    role="banner"
    data-section-id="header"
    data-section-type="header-section"
  >
    <div class="grid grid--no-gutters grid--table site-header__inner">
      <div class="grid__item header_gap medium-up--one-sixth"></div>
      <div class="grid__item header_logo medium-up--two-thirds">
        <div
          class="site-header__logo h1"
          itemscope=""
          itemtype="http://schema.org/Organization"
        >
          <Link to="/" className="site-header__logo-link">
            <img
              class="site-header__logo-image"
              alt="Chrome Hearts"
              src={logo}
            ></img>
            {/* <Img
                fixed={data.placeholderImage.childImageSharp.fixed}
                className="site-header__logo-image"
                alt="Chrome Hearts"
              ></Img> */}
          </Link>
        </div>
      </div>

      <div class="grid__item header_gap medium-up--one-sixth text-right">
        <a href="/cart" class="site-header__link site-header__cart cart-link">
          <span class="icon icon-cart" aria-hidden="true"></span>
          <span class="icon__fallback-text">Cart</span>
        </a>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
