import { Link } from "gatsby"
import React from "react"

// <div id="shopify-section-footer" class="shopify-section footer-section"></div>
const Footer = () => (
    <footer class="site-footer" role="contentinfo">
      <div class="footer_links_wrapper">
        <div class="footer_links_left">
          <Link to="/return-policy" className="footer_link">GENERAL</Link>
        </div>
        <div class="footer_links_left">
          <Link
            to="/CTSCAD-ND.pdf"
            className="footer_link"
          >CA SUPPLY CHAIN ACT</Link>
          <Link
            to="/privacy-policy"
            className="footer_link"
          >PRIVACY POLICY</Link>
        </div>
        <div class="footer_links_right">
          <Link to="/terms-of-use" className="footer_link">TERMS &amp; CONDITIONS</Link>
          <a class="footer_link" href="mailto:contact@chromehearts.com">
            CONTACT
          </a>
        </div>
      </div>
      <div class="footer_copyright">
        &copy; CHROME HEARTS LLC. ALL RIGHT RESERVED
      </div>
    </footer>
)

export default Footer
